import React, { useState, useContext } from "react";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextPrivider({ children }) {
  const currentLang = localStorage.getItem("lng");
  const [language, changeLanguage] = useState(currentLang || "en");
  let htmlElement = document.getElementsByTagName("html")[0];
  if (currentLang === "en") {
    htmlElement.setAttribute("dir", "ltr");
  } else {
    htmlElement.setAttribute("dir", "rtl");
  }
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
