import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

//style
import "./about.scss";

//images
import Bg from "../../../assets/images/slide-1.png";
import whiteBorder from "../../../assets/images/white-mask.png";
import Mission from "../../../assets/images/Mission.png";
import Vision from "../../../assets/images/Vision.png";
import Goals from "../../../assets/images/Goals.png";
import useTranslation from "../../customHooks/translation";

function About(props) {
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  return (
    <>
      <section className="about" name="aboutSection">
        <Container fluid>
          <div className="title text-center">
            <h4 className="">{useTranslation().about.whoWeAre}</h4>
            <h2>{getByKey("who_are_we_title")}</h2>
          </div>
          <Row>
            <Col className="text-center" md={6}>
              <div className="about__imgs">
                <img src={Bg} className="large-img" alt="large-img" />
                <img src={Bg} className="small-img" alt="small-img" />
                <img src={whiteBorder} className="white-img" alt="white-img" />
              </div>
            </Col>

            <Col className="list-col" md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h4 className="my-4 elements-aligns-right">
                    <img src={Mission} alt="Mission" className="about-icons" />
                    {useTranslation().about.mission} :
                  </h4>
                  <p className="elements-aligns-right">
                    {getByKey("who_are_we_mission")}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4 className="elements-aligns-right">
                    <img src={Vision} alt="Vision" className="about-icons" />{" "}
                    {useTranslation().about.vision} :
                  </h4>
                  <p className="elements-aligns-right">
                    {getByKey("who_are_we_vision")}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4 className="elements-aligns-right">
                    <img src={Goals} alt="Goals" className="about-icons" />{" "}
                    {useTranslation().about.goals} :
                  </h4>
                  <p className="elements-aligns-right">
                    {getByKey("who_are_we_goals")}
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;
