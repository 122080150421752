import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

//style
import "./q&a.scss";

//images
import person from "../../../assets/images/person.png";
import greenBg from "../../../assets/images/green-mask.png";
import bg from "../../../assets/images/bg.png";
import useTranslation from "../../customHooks/translation";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function QA(props) {
  let _key = 0;
  const [paging, setPaging] = useState(false);
  const [startIndex, setStartIndex] = useState(false);

  useEffect(() => {
    if (props.dataRes && props.dataRes.faqs) {
      setPaging(Math.ceil(props.dataRes.faqs.length / 5));
    }
  });
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  return (
    <>
      <section className="QA" name="QASection" id="QASection">
        <Container fluid>
          <div className="title text-center">
            <h6>{useTranslation().fAQ.qAndA}</h6>
            <h2>{getByKey("faq_title")}</h2>
          </div>
          <Row className="elements-aligns-right">
            <Col className="p-0" md={6}>
              <Slider {...settings}>
                {props.dataRes &&
                  props.dataRes.faqs &&
                  Array(paging)
                    .fill(0)
                    .map((Pe, Pi) => {
                      return (
                        <div key={_key++}>
                          {props.dataRes &&
                            props.dataRes.faqs &&
                            props.dataRes.faqs
                              .slice(Pi * 5, (Pi + 1) * 5)
                              .map((e, i) => {
                                return (
                                  <ListGroup.Item
                                    key={_key++}
                                    style={{ overflowWrap: "break-word" }}
                                  >
                                    <h6>
                                      <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="QA-icons"
                                      />
                                      {e.ques}
                                    </h6>
                                    <p>{e.ans}</p>
                                  </ListGroup.Item>
                                );
                              })}
                        </div>
                      );
                    })}
              </Slider>
            </Col>

            <Col className="text-center" md={6}>
              <div className="QA__imgs">
                <img src={person} className="person-img" alt="person" />
                <img src={greenBg} className="greenBg-img" alt="person" />
                <img src={bg} className="bg-img" alt="pattern" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default QA;
