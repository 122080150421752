import React from "react";
import { Navbar, Nav } from "react-bootstrap";

//style
import "./navBarRegister.scss";

//images
import logo from "../../../assets/images/logo.png";
import useTranslation from "../../customHooks/translation";
import LanguageHandler from "../../LanguageHandler";

function NavBarRegister() {
  return (
    <>
      {/* <div className="menu container-fluid mt-5"> */}
      <div
        style={{
          position: "fixed",
          zIndex: "9999999999999",
          transform: "translateY(47px)",
          transition: "transform .6s",
          width: "100%",
        }}
      >
        <Navbar
          style={{ position: "fixed !important", width: "90%", margin: "auto" }}
          className="menu__content p-0"
          expand="lg"
          variant="light"
          bg="light"
        >
          <Navbar.Brand href="/">
            <img className="logo mr-3 ml-5" src={logo} alt="Logo" />
            CUT PLATFORM
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav as="ul">
              <Nav.Link className="text-center m-0" href="/">
                {useTranslation().register.navHome}
              </Nav.Link>
              {/* <a className="text-center m-0" href="#">
                Arabic
              </a> */}
              <LanguageHandler />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {/* </div> */}
    </>
  );
}

export default NavBarRegister;
