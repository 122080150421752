import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

//style
import "./footer.scss";

//images
import logo from "../../assets/images/logo.png";
import useTranslation from "../customHooks/translation";
import FAQLink from "../Home/Q&A/FAQ";

function Footer() {
  const {
    FAQs,
    GetInTouch,
    PrivacyPolicy,
    copyright,
    policyInfo,
    termsOfUse,
  } = useTranslation().footer;
  return (
    <div>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col xs="12" sm="12" md="6" className="d-flex footer-logo-section">
              <div className="logo-item">
                <div>
                  <img
                    src={logo}
                    className="logo mb-3 footer-logo"
                    alt="logo"
                  />
                  <p className="footer-p">{copyright}</p>
                </div>
              </div>
            </Col>
            <Col xs="6" sm="6" md="3" className="text-center">
              <h6 className="text-uppercase mb-3">{policyInfo}</h6>
              <Link to="/Terms">{PrivacyPolicy}</Link>
              <Link to="/Terms">{termsOfUse}</Link>
              <FAQLink />
            </Col>
            <Col xs="6" sm="6" md="3" className="text-center">
              <h6 className="text-uppercase mb-3">{GetInTouch}</h6>
              <a href="/">sales@Cut.com</a>
              <a href="/">support@Cut.com</a>
              <a href="/">+966 1237 2382 221</a>
              <ul className="list-inline social-link mt-3">
                <li className="list-inline-item m-2">
                  <a href="/">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="list-inline-item m-2">
                  <a href="/">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li className="list-inline-item m-2">
                  <a href="/">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className="list-inline-item m-2">
                  <a href="/">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
