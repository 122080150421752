import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//style
import "./partners.scss";
//images
import Bg from "../../../assets/images/slide-1.png";
import useTranslation from "../../customHooks/translation";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Partners(props) {
  function isOdd(num) {
    return num % 2;
  }
  return (
    <>
      <section className="partners text-center" name="partnersSection">
        <Container fluid>
          <div className="title text-center">
            <h6>{useTranslation().parteners.cutPartener}</h6>
            <h2>{useTranslation().parteners.partenersP}</h2>
          </div>
          <h3 className="mb-5">{useTranslation().parteners.resturant} </h3>
          <div style={{ direction: "ltr" }}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              arrows={false}
              dotListClass="custom-dot-list-style"
              className="test"
            >
              {props.dataRes &&
                props.dataRes.ownerPartners &&
                props.dataRes.ownerPartners.map((e, i) => {
                  return (
                    <div key="i" className="partners__card">
                      <div className={!isOdd(i) ? null : "partners__card__mt"}>
                        <span>
                          <FontAwesomeIcon icon={faStar} /> {e.rate}
                        </span>
                        <img src={e.photo ? e.photo : Bg} alt={e.title} />
                        <h5>{e.title}</h5>
                        <p>{e.content}</p>
                      </div>
                    </div>
                  );
                })}
            </Carousel>

            <h3 className="mt-4 mb-5">
              {useTranslation().parteners.cutSuplliers}{" "}
            </h3>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              arrows={false}
              dotListClass="custom-dot-list-style"
            >
              {props.dataRes &&
                props.dataRes.supplierPartners &&
                props.dataRes.supplierPartners.map((e, i) => {
                  return (
                    <div key="i" className="partners__card">
                      <div className={!isOdd(i) ? null : "partners__card__mt"}>
                        <span>
                          <FontAwesomeIcon icon={faStar} /> {e.rate}
                        </span>
                        <img src={e.photo ? e.photo : Bg} alt={e.title} />
                        <h5>{e.title}</h5>
                        <p>{e.content}</p>
                      </div>
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Partners;
