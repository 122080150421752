import React from "react";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { AiFillAccountBook } from "react-icons/ai";

//style
import "./header.scss";

//images
import slide1 from "../../assets/images/slide-1.png";

//components
import Menu from "./Navbar/Navbar";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  easing: "ease-in-out",
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Header(props) {
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  return (
    <>
      <div className="header">
        <Menu />
        <Row>
          <Col
            md={7}
            className="header__pattern d-flex justify-content-center elements-aligns-right"
          >
            <h6>{getByKey("about_cut_title")}</h6>
            <h2 className="mb-4">{getByKey("about_cut_content")}</h2>
          </Col>
          <Col md={5} className="header__slider pb-5">
            <Slider {...settings}>
              {props.dataRes &&
                props.dataRes.slider &&
                props.dataRes.slider.map((e) => {
                  return (
                    <div key="e.photo">
                      <img
                        className="d-block w-100"
                        src={e.photo}
                        alt="First slide"
                      />
                    </div>
                  );
                })}
            </Slider>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Header;
