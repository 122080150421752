import React from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import useTranslation from "./customHooks/translation";

export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();
  const { lang } = useTranslation().navbar;

  return (
    <a
      onClick={(e) => {
        // let lang = toggleLanguage();
        const lang = localStorage.getItem("lng");
        if (lang === "en") {
          localStorage.setItem("lng", "ar");
          changeLanguage("ar");
          window.location.reload();
        } else {
          localStorage.setItem("lng", "en");
          changeLanguage("en");
          window.location.reload();
        }
      }}
      className="langLink"
    >
      {lang}
    </a>
    // <select
    //   value={language}
    //   onChange={(e) => {
    //     localStorage.setItem("lng", e.target.value);
    //     changeLanguage(e.target.value);
    //     window.location.reload();
    //   }}
    // >
    //   <option value="en">En- English</option>
    //   <option value="ar">En- Arabic</option>
    // </select>
  );
}
