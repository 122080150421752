import React from "react";
import { Link } from "react-scroll";
import useTranslation from "../../customHooks/translation";

const FAQLink = () => {
  return (
    <Link
      to="QASection"
      /*spy={true}*/ smooth={true}
      offset={50}
      duration={800}
    >
      {useTranslation().footer.FAQs}
    </Link>
  );
};

export default FAQLink;
