import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

// global style
import "./global.scss";

//components
import Footer from "./compontents/Footer/Footer";
//pages
import Home from "./compontents/Home/Home";
import Register from "./compontents/Register/Register";
import VerificationCode from "./compontents/Register/VerificationCode/VerificationCode";
import Terms from "./compontents/Terms/Terms";

// for scrolling
const body = document.body;
const showScorllUpBtn = "show-ScorllUpBtn";
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    body.classList.remove(scrollUp);
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    // down
    body.classList.remove(scrollUp);
    body.classList.add(scrollDown);
  } else if (
    currentScroll < lastScroll &&
    body.classList.contains(scrollDown)
  ) {
    // up
    body.classList.remove(scrollDown);
    body.classList.add(scrollUp);
  }
  lastScroll = currentScroll;

  if (currentScroll > 50) {
    body.classList.add(showScorllUpBtn);
  } else {
    body.classList.remove(showScorllUpBtn);
  }
});

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/Register">
            <Register />
          </Route>
          <Route path="/VerificationCode">
            <VerificationCode />
          </Route>
          <Route path="/Terms">
            <Terms />
          </Route>
        </Switch>
        <Footer />
        <div
          className="scrollTopBtn text-center"
          onClick={() => animateScroll.scrollToTop()}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
      </div>
    </Router>
  );
}

export default App;
