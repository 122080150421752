import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//style
import "./services.scss";

//images
import services1 from "../../../assets/images/services-1.png";
import services2 from "../../../assets/images/services-2.png";
import services3 from "../../../assets/images/services-3.png";
import useTranslation from "../../customHooks/translation";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Services(props) {
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  return (
    <>
      <section className="services" name="servicesSection">
        <Container fluid>
          <div className="title text-center">
            <h4>{useTranslation().services.cutServices}</h4>
            <h2>{getByKey("service_title")}</h2>
          </div>
          <div className="text-center" dir="ltr">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              arrows={false}
              dotListClass="custom-dot-list-style"
            >
              {props.dataRes &&
                props.dataRes.service &&
                props.dataRes.service.map((e) => {
                  return (
                    <div key="e.id" className="services__col">
                      <div className="services__card">
                        <img
                          src={e.photo ? e.photo : services2}
                          alt="Delivery Fast"
                        />
                        <h5>{e.title}</h5>
                        <p style={{ wordBreak: "break-all" }}>{e.content}</p>
                      </div>
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Services;
