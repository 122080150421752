import React, { useState } from "react";
import LanguageHandler from "../../LanguageHandler";
import useTranslation from "../../customHooks/translation";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import strings from "../../../Localization";
import { useDropdownToggle } from "react-overlays";
import { useMediaPredicate } from "react-media-hook";

//style
import "./navbar.scss";
//images
import logo from "../../../assets/images/logo.png";

function Menu() {
  const {
    whoWeAre,
    services,
    feathers,
    parteners,
    howItWork,
    register,
    contactUs,
    q_a,
  } = useTranslation().navbar;

  const lng = "english";
  const lessThan990 = useMediaPredicate("(max-width: 990px)");

  // const [collapsed, setCollapsed] = useState(true);
  // let [toggleCollapsed, setToggleCollapsed] = useState("collapsed");
  // let [toggleShow, setToggleShow] = useState("");
  const [expanded, setExpanded] = useState(false);
  function toggleExpanded() {
    setExpanded(!expanded);
    // setCollapsed(!collapsed);
    // if (!collapsed) {
    //   setToggleCollapsed("");
    //   setToggleShow("show");
    // } else {
    //   setToggleCollapsed("collapsed");
    //   setToggleShow("");
    // }
  }

  return (
    <>
      {/* <div className="menu container-fluid mt-5"> */}
      <div
        style={{
          position: "fixed",
          zIndex: "9999999999999",
          transform: "translateY(47px)",
          transition: "transform .6s",
          width: "100%",
        }}
        className="m-auto"
      >
        <Navbar
          style={{ position: "fixed !important", width: "90%", margin: "auto" }}
          pullRight
          className="menu__content p-0 navbar-right _navbar"
          expand="lg"
          expanded={expanded}
          variant="light"
          bg="light"
          // style={{ direction: "rtl", float: "left" }}
        >
          <Navbar.Brand href="/">
            <img className="logo mr-3 ml-5" src={logo} alt="Logo" />
            CUT PLATFORM
            {strings.hi}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={lessThan990 && toggleExpanded}
            // className={toggleCollapsed}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`justify-content-end`}
          >
            <Nav defaultActiveKey="/" as="ul">
              <Link
                className="text-center m-0"
                to="aboutSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {whoWeAre}
              </Link>
              <Link
                className="text-center m-0"
                to="servicesSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {services}
              </Link>
              <Link
                className="text-center m-0"
                to="featuresSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {feathers}
              </Link>
              <Link
                className="text-center m-0"
                to="partnersSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {parteners}
              </Link>
              <Link
                className="text-center m-0"
                to="QASection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {q_a}
              </Link>
              <Link
                className="text-center m-0"
                to="HowItWorkSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {howItWork}
              </Link>
              <Link
                className="text-center m-0"
                to="contactSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                onClick={lessThan990 && toggleExpanded}
              >
                {contactUs}
              </Link>
              <Nav.Link
                className="text-center m-0"
                href="/Register"
                onClick={lessThan990 && toggleExpanded}
              >
                {register}
              </Nav.Link>
              <LanguageHandler onClick={lessThan990 && toggleExpanded} />
              {/* <a onClick={onChangeLang} className="text-center m-0" href="#">
                {lang}
              </a> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {/* </div> */}
    </>
  );
}

export default Menu;
