import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

//style
import './verificationCode.scss';

//components
import NavBarRegister from '../NavBarRegister/NavBarRegister';

function VerificationCode() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <NavBarRegister />
        <div className='verification Register contactSection'>
          <Container fluid>
            <h2>Restaurant owner Registration</h2>
            <h5 className='mb-4'>Verification Code</h5>
            <Row className='mb-3 text-center'>
              <Col md='12' lg='12'>
                <Form.Group controlId='Phone'>
                  <Form.Control type='number' placeholder='Code' />
                </Form.Group>
              </Col>

              <Button
                type='submit'
                className='Send-btn mt-5'
                onClick={handleShow}
              >
                Submit
              </Button>
            </Row>
          </Container>
          <Modal className='text-center' show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <FontAwesomeIcon icon={faEnvelopeOpen} />
              <h5>Account is pending</h5>
              <p>Please wait until Account complete the verification process</p>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/' className='Send-btn'>
                Submit
              </Link>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default VerificationCode;
