import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

//style
import "./howitwork.scss";

//images
import MacBg from "../../../assets/images/mac-screen.png";
import slide1 from "../../../assets/images/slide-show.png";
import useTranslation from "../../customHooks/translation";

function HowItWork(props) {
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  return (
    <>
      <section className="HowItWork" name="HowItWorkSection">
        <Container fluid>
          <div className="title text-center">
            <h6>{useTranslation().howItWork.howItWork}</h6>
            <h2>{getByKey("how_it_work_title")}</h2>
          </div>
          <Row>
            <Col
              className="d-flex flex-column align-item-center justify-content-center"
              md={6}
            >
              <p className="elements-aligns-right">{getByKey("how_it_work")}</p>
            </Col>

            <Col className="text-center" md={6}>
              <div className="slideShow">
                <img src={MacBg} className="screen-img" alt="screen-img" />
                <Carousel
                  fade={true}
                  interval={5000}
                  nextIcon={<FontAwesomeIcon icon={faCaretRight} />}
                  prevIcon={<FontAwesomeIcon icon={faCaretLeft} />}
                >
                  <Carousel.Item
                    style={{
                      transform: "translateX(100%)",
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src={slide1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item
                    style={{
                      transform: "translateX(100%)",
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src={slide1}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item
                    style={{
                      transform: "translateX(100%)",
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src={slide1}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HowItWork;
