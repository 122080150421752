import React from "react";
import { Container } from "react-bootstrap";
import { Checkbox } from "antd";

//style
import "./terms.scss";

//components
import NavBarRegister from "../Register/NavBarRegister/NavBarRegister";

function Terms() {
  return (
    <>
      <div>
        <NavBarRegister />
        <div className="Terms Register">
          <Container fluid>
            <h2>Terms & Privacy Policy For Cut</h2>
            <h5 className="mb-3">Privacy Policy For Cut</h5>

            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiamam
              nonumy eirmod tempor invidunt ut labore et dolore magnaliquy erat,
              sed diam voluptua.
            </p>

            {/* <Checkbox>Accept Terms and conditions for cut platform</Checkbox> */}
          </Container>
        </div>
      </div>
    </>
  );
}

export default Terms;
