import React, { useState } from "react";
import { FormControl, Container, Row, Col } from "react-bootstrap";

//style
import "./contactSection.scss";
import { Form, Input, InputNumber, Button, notification, Select } from "antd";
import axios from "axios";
import useTranslation from "../../customHooks/translation";
const { Option } = Select;

const { TextArea } = Input;
function ContactSection() {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, title, message, duration = 0) => {
    notification[type]({
      message: title,
      duration: duration,
      description: message,
    });
  };
  const handleSubmit = (values) => {
    notification.destroy();

    axios({
      method: "post",
      url: "https://cut-backend-2.appssquare.com/api/contactUs",
      data: values,
    })
      .then(function (response) {
        form.resetFields();

        openNotificationWithIcon(
          "success",
          "Sent",
          "Thank  you for contacting us, we will contact you soon",
          2
        );
      })
      .catch(function (error) {
        openNotificationWithIcon(
          "error",
          "Registration error",
          error.response.data.message
        );
      });
  };
  return (
    <>
      <section className="contactSection text-center" name="contactSection">
        <Container fluid>
          <div className="title text-center">
            <h6>{useTranslation().contact.contactUs}</h6>
            <h2>{useTranslation().contact.contactP}</h2>
          </div>
          <Form
            name="basic"
            className="login-form"
            form={form}
            initialValues={{ remember: true, prefix: "86" }}
            onFinish={handleSubmit}
            // onFinishFailed={onFinishFailed}
          >
            <Row className="mb-3 text-center">
              <Col md="12" lg="12">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Full Name!",
                    },
                  ]}
                >
                  <Input placeholder={useTranslation().contact.fullName} />
                </Form.Item>
              </Col>
              <Col md="6" lg="6">
                <Form.Item
                  name="subject"
                  initialValue="Select subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Subject!",
                    },
                  ]}
                >
                  {/* <Input placeholder='Subject' /> */}

                  <Select className="ant-input">
                    <Option
                      disabled
                      value="Select subject"
                      style={{ color: "#bfbfbf" }}
                    >
                      {useTranslation().contact.selectSubject}
                    </Option>
                    <Option value="suggest">
                      {useTranslation().contact.suggest}
                    </Option>
                    <Option value="complain">
                      {useTranslation().contact.complain}
                    </Option>
                    <Option value="new_request">
                      {useTranslation().contact.newRequest}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md="6" lg="6">
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    value={phoneNumber}
                    type="number"
                    placeholder={useTranslation().contact.phoneNumber}
                  />
                </Form.Item>
              </Col>
              <Col md="12" lg="12">
                <Form.Item
                  name="message"
                  rules={[
                    {
                      min: 40,
                    },
                    {
                      len: true,
                      message: "Please input your Message!",
                    },
                  ]}
                >
                  {/* <TextArea
                    rows={4}
                    placeholder={useTranslation().contact.message}
                    style={{ borderRadius: "0px !important" }}
                  /> */}
                  <div
                    style={{
                      borderRadius: "27px",
                      border: "1px solid #000",
                      padding: "10px 5px",
                    }}
                  >
                    <textarea
                      className="form-control w-100 m-0 border-0"
                      name=""
                      id=""
                      cols="30"
                      rows="4"
                      placeholder={useTranslation().contact.message}
                      // style={{ overflowY: "auto" }}
                    ></textarea>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Button htmlType="submit" className="Send-btn text-uppercase">
              {useTranslation().contact.send}
            </Button>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default ContactSection;
