const ar = {
  navbar: {
    lang: "English",
    whoWeAre: "من نحن",
    services: "الخدمات",
    feathers: "الميزات",
    parteners: "شركائنا",
    howItWork: "كيف تعمل",
    register: "تسجيل دخول",
    contactUs: "اتصل بنا",
    q_a: "أسئله وأجوبة",
  },
  about: {
    whoWeAre: "من نحن",
    mission: "مهمتنا",
    vision: "رؤيتنا",
    goals: "هدفنا",
  },
  contact: {
    selectSubject: "اختر موضوع",
    contactUs: "اتصل بنا",
    contactP: "هنا فى هذا القسم يمكنا التواصل معنا وبلا بلا بلا",
    suggest: "اقترح",
    complain: "قدم شكوى",
    newRequest: "طلب جديد",
    phoneNumber: "رقم الهاتف",
    fullName: "الاسم بالكامل",
    message: "اكتب رسالتك",
    send: "ارسال",
  },
  services: {
    cutServices: "خدمات كات",
  },
  features: {
    cutFeatures: "مزايا كات",
  },
  parteners: {
    cutPartener: "شركاء كات",
    partenersP: "هنا فى هذا الجزء سوف نعرض اهم المزايا فى تطبيق كات",
    cutPartener: "شركاء كات",
    cutSuplliers: "موردين كات",
    resturant: "مطعم كات",
  },
  fAQ: {
    qAndA: "اسئله واجوبه",
  },
  howItWork: {
    howItWork: "كيف تعمل ؟",
  },
  register: {
    navHome: "الصفحة الرئيسية",
    navLang: "English",
    registerBody: {
      supplierRegistration: "تسجيل الموردين",
      RestaurantOwnerRegistration: "تسجيل مالك المطعم",
      basicInfo: "المعلومات الرئيسيه",
      inputs: {
        fullName: "الاسم بالكامل",
        phone: "رقم الهاتف",
        email: "البريد الالكترونى",
        company: "اسم الشركه",
        overView: "النظرة العامه",
        uploadPhoto: "ارفع صورتك/الشعار",
        uploadLetter: "ارفع خطاب طلب التسجيل الخاص بك",
        addAdress: "أضف عنوان",
        password: "كلمة المرور",
        passwordConfirmation: "تأكيد كلمة المرور",
        confirmTerms: "قبول الشروط والأحكام لمنصة كات",
      },
      registerBtn: "تسجيل",
    },
  },
  downloadSection: {
    downloadMessage: "حمل تطبيق كات الان!",
  },
  footer: {
    copyright: "جميع الحقوق محفوظه © كات 2020",
    policyInfo: "معلومات السياسة",
    PrivacyPolicy: "السياسة الامنية",
    termsOfUse: "تعليمات الاستخدام",
    FAQs: "الأسئلة الشائعه",
    GetInTouch: "تواصل معنا",
  },
};

export default ar;
