import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Form, Input, Button, Checkbox, notification, Upload } from "antd";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  ProfileOutlined,
  LockOutlined,
} from "@ant-design/icons";

import axios from "axios";

//style
import "antd/dist/antd.css";
import "./register.scss";
import { Link } from "react-router-dom";

//components
import SuccessModal from "./VerificationCode/SuccessModal";
import NavBarRegister from "./NavBarRegister/NavBarRegister";
import useTranslation from "../customHooks/translation";
const { TextArea } = Input;
function Register() {
  const [uploaded, setUploaded] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(false);
  const [successData, setSuccessData] = useState(false);
  const [logoId, setLogoId] = useState(false);
  const [letterId, setLetterId] = useState(false);

  const openNotificationWithIcon = (message) => {
    notification["error"]({
      message: "Registration error",
      duration: 0,
      description: message,
    });
  };

  const onFinish = (values) => {
    notification.destroy();
    values.type = "Owner";
    axios({
      method: "post",
      url: "https://cut-backend-2.appssquare.com/api/user/register",
      data: values,
    })
      .then(function (response) {
        setSuccessData(response.data);
      })
      .catch(function (error) {
        openNotificationWithIcon(error.response.data.message);
      });
  };
  const onFinishSupplier = (values) => {
    notification.destroy();
    values.type = "Supplier";
    values.logo = logoId;
    values.letter = letterId;
    axios({
      method: "post",
      url: "https://cut-backend-2.appssquare.com/api/user/register",
      data: values,
    })
      .then(function (response) {
        setSuccessData(response.data);
      })
      .catch(function (error) {
        openNotificationWithIcon(error.response.data.message);
      });
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    setUploaded(true);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("images", file);
    try {
      const res = await axios.post(
        "https://cut-backend-2.appssquare.com/api/tempUpload",
        fmData,
        config
      );
      onSuccess("Ok");
      setLetterId(res.data.data.id);
    } catch (err) {
      openNotificationWithIcon(
        "Error please remove it and try to upload again"
      );
    }
  };
  const uploadLogoImage = async (options) => {
    const { onSuccess, file } = options;
    setUploadedLogo(true);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("images", file);
    try {
      const res = await axios.post(
        "https://cut-backend-2.appssquare.com/api/tempUpload",
        fmData,
        config
      );

      onSuccess("Ok");
      setLogoId(res.data.data.id);
    } catch (err) {
      openNotificationWithIcon(
        "Error please remove it and try to upload again"
      );
    }
  };

  const uploadProps = {
    onRemove(info) {
      setUploaded(false);
    },
  };

  const uploadLogoProps = {
    onRemove(info) {
      setUploadedLogo(false);
    },
  };
  const {
    supplierRegistration,
    RestaurantOwnerRegistration,
    basicInfo,
    inputs: {
      fullName,
      phone,
      email,
      company,
      addAdress,
      confirmTerms,
      overView,
      password,
      passwordConfirmation,
      uploadLetter,
      uploadPhoto,
    },
    registerBtn,
  } = useTranslation().register.registerBody;
  let terms_1;
  let termsLink_2;
  let termsLink_3;
  if (localStorage.getItem("lng") === "en") {
    terms_1 = confirmTerms.slice(0, 6);
    termsLink_2 = confirmTerms.slice(7, 27);
    termsLink_3 = confirmTerms.slice(28);
  } else {
    terms_1 = confirmTerms.slice(0, 4);
    termsLink_2 = confirmTerms.slice(5, 20);
    termsLink_3 = confirmTerms.slice(21);
  }

  return (
    <div>
      <NavBarRegister />
      <div className="Register contactSection">
        <Container fluid>
          <Tabs
            defaultActiveKey="supplier"
            className="text-center mt-5 registerTopBtns"
          >
            <Tab eventKey="supplier" title={supplierRegistration}>
              <h2 className="mt-4 elements-aligns-right">
                {supplierRegistration}
              </h2>
              <h5 className="mb-4 elements-aligns-right">{basicInfo}</h5>
              <Form
                name="basic"
                className="login-form"
                initialValues={{ remember: true, prefix: "86" }}
                onFinish={onFinishSupplier}
                // onFinishFailed={onFinishFailed}
              >
                <Row className="mb-3 text-center">
                  <Col md="12" lg="12">
                    <Form.Item
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Full Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={fullName}
                        prefix={<UserOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={phone}
                        type="number"
                        prefix={<PhoneOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={email}
                        prefix={<MailOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Company name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={company}
                        prefix={<ProfileOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="overview"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Overview!",
                        },
                      ]}
                    >
                      <div
                        style={{
                          borderRadius: "27px",
                          border: "1px solid #000",
                          padding: "10px 5px",
                        }}
                      >
                        <textarea
                          className="form-control w-100 m-0 border-0"
                          name=""
                          id=""
                          cols="30"
                          rows="4"
                          placeholder={overView}
                          // style={{ overflowY: "auto" }}
                        ></textarea>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="logo"
                      rules={[
                        {
                          required: true,
                          message: "Please upload your photo/logo!",
                        },
                      ]}
                    >
                      <Upload
                        accept=".png,jpg,jpeg"
                        customRequest={uploadLogoImage}
                        {...uploadLogoProps}
                      >
                        {!uploadedLogo && (
                          <Button
                            className="custom-uploadBtn"
                            icon={<UploadOutlined />}
                          >
                            {uploadPhoto}
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="letter"
                      rules={[
                        {
                          required: true,
                          message: "Please upload your letter!",
                        },
                      ]}
                    >
                      <Upload
                        customRequest={uploadImage}
                        {...uploadProps}
                        accept=".pdf,docx"
                      >
                        {!uploaded && (
                          <Button
                            className="custom-uploadBtn"
                            icon={<UploadOutlined />}
                          >
                            {uploadLetter}
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.List
                      name="addresses"
                      rules={[
                        {
                          validator: async (_, addresses) => {
                            if (!addresses || addresses.length < 1) {
                              return Promise.reject(
                                new Error("At least 1 address")
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={`Address ${index + 1}:`}
                              required={true}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Please input address's name or delete this field.",
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder="Address"
                                  className="dynamicInput"
                                />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item className="dynamic-add-button">
                            <div className="elements-aligns-right">
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                {addAdress}
                              </Button>
                            </div>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={password}
                        prefix={<LockOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="passwordConfirm"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder={passwordConfirmation}
                        prefix={<LockOutlined className="input-wIcon" />}
                      />
                    </Form.Item>

                    <Form.Item
                      name="remember"
                      className="checkBox-antd elements-aligns-right"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Should accept agreement"),
                        },
                      ]}
                    >
                      <div className="elements-aligns-right">
                        <Checkbox>
                          {terms_1}
                          <Link to="/Terms"> {termsLink_2} </Link>
                          {termsLink_3}
                        </Checkbox>
                      </div>
                    </Form.Item>

                    <Form.Item className="submit-antd">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="Send-btn mt-5"
                      >
                        {registerBtn}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="restaurant" title={RestaurantOwnerRegistration}>
              <h2 className="elements-aligns-right my-3">
                {RestaurantOwnerRegistration}
              </h2>
              <Form
                name="basic"
                className="login-form"
                initialValues={{ remember: true, prefix: "86" }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
              >
                <Row className="mb-3 text-center">
                  <Col md="12" lg="12">
                    <Form.Item
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Full Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={fullName}
                        prefix={<UserOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={phone}
                        type="number"
                        prefix={<PhoneOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={email}
                        prefix={<MailOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Company name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={company}
                        prefix={<ProfileOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={password}
                        prefix={<LockOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="passwordConfirm"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder={passwordConfirmation}
                        prefix={<LockOutlined className="input-wIcon" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="remember"
                      className="checkBox-antd"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Should accept agreement"),
                        },
                      ]}
                    >
                      <div className="elements-aligns-right">
                        <Checkbox>
                          {terms_1}
                          <Link to="/Terms"> {termsLink_2} </Link>
                          {termsLink_3}
                        </Checkbox>
                      </div>
                    </Form.Item>

                    <Form.Item className="submit-antd">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="Send-btn mt-5"
                      >
                        {registerBtn}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tab>
          </Tabs>
        </Container>
      </div>
      {successData && <SuccessModal resData={successData} />}
    </div>
  );
}

export default Register;
