const en = {
  navbar: {
    lang: "العربيه",
    whoWeAre: "Who we are ",
    services: "Services",
    feathers: "feathers",
    parteners: "Our parteners",
    howItWork: "How it works",
    register: "Register",
    contactUs: "Contact us",
    q_a: "Q & A",
  },
  about: {
    whoWeAre: "whoWeAre",
    mission: "mission",
    vision: "vision",
    goals: "goals",
  },
  contact: {
    selectSubject: "select subject",
    contactUs: "contact us",
    contactP:
      "here In this section we will features for this web In cut platform",
    suggest: "suggest",
    complain: "complain",
    newRequest: "new request",
    send: "send",
    phoneNumber: "phone number",
    fullName: "full name",
    message: "message",
  },
  services: {
    cutServices: "cut services",
  },
  features: {
    cutFeatures: "cut features",
  },
  parteners: {
    cutPartener: "cut Partener",
    partenersP:
      "Here In this section we will features for this web In cut platform",
    cutPartener: "cut Partener",
    cutSuplliers: "cut Suplliers",
    resturant: "Resturant Partener",
  },
  fAQ: {
    qAndA: "Q & A",
  },
  howItWork: {
    howItWork: "How it work ?",
  },
  register: {
    navHome: "Home",
    navLang: "العربيه",
    registerBody: {
      supplierRegistration: "supplier Registration",
      RestaurantOwnerRegistration: "Restaurant Owner Registration",
      basicInfo: "Basic Info",
      inputs: {
        fullName: "Full Name",
        phone: "Phone",
        email: "Email",
        company: "Company Name",
        overView: "Overview",
        uploadPhoto: "Upload your photo/Logo",
        uploadLetter: "Upload your Registration Request Letter",
        addAdress: "Add Address",
        password: "Password",
        passwordConfirmation: "Password Confirm",
        confirmTerms: "Accept Terms and conditions for cut platform",
      },
      registerBtn: "Register",
    },
  },
  downloadSection: {
    downloadMessage: "Download CUT Application NOW !",
  },
  footer: {
    copyright: "Copyright © 2020 | CUT | All rights reserved",
    policyInfo: "POLICY INFO",
    PrivacyPolicy: "Privacy policy",
    termsOfUse: "terms of use",
    FAQs: "FAQs",
    GetInTouch: "Get in touch",
  },
};

export default en;
