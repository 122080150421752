import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

//style
import "./features.scss";

//images
import Bg from "../../../assets/images/slide-1.png";
import whiteBorder from "../../../assets/images/white-mask.png";
import useTranslation from "../../customHooks/translation";

function Features(props) {
  const getByKey = (key) => {
    return (
      props.dataRes &&
      props.dataRes.setting.filter((entry) => entry.key == key)[0].content
    );
  };
  function getFeatures() {
    if (getByKey("features")) {
      getByKey("features")
        .split(".")
        .map((feature) => {
          return <li>{feature}</li>;
        });
    }
  }
  return (
    <>
      <section className="features" name="featuresSection">
        <Container fluid>
          <div className="title text-center">
            <h4>{useTranslation().features.cutFeatures}</h4>
            <h2>{getByKey("features_title")}</h2>
          </div>
          <Row>
            <Col sm={12} md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <ol
                    style={{ listStyleType: "none", padding: "0px 40px" }}
                    className="elements-aligns-right"
                  >
                    {/* {getFeatures()} */}

                    {getByKey("features") && (
                      <li className="mb-2 hasLineBreak">
                        {getByKey("features")}
                      </li>
                    )}
                  </ol>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col sm={12} className="text-right features-img" md={6}>
              <div className="about__imgs">
                <img src={Bg} className="large-img" alt="large-img" />
                <img src={Bg} className="small-img" alt="small-img" />
                <img src={whiteBorder} className="white-img" alt="white-img" />
                <img
                  src={Bg}
                  className="smallest-img small-img"
                  alt="smallest-img"
                />
                <img
                  src={whiteBorder}
                  className="white-img2 white-img"
                  alt="white-img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Features;
