import React, { useState, useEffect } from "react";
import axios from "axios";

//style
import "./home.scss";

//components
import Header from "../Header/Header";
import About from "./About/About";
import Services from "./Services/Services";
import Features from "./Features/Features";
import Partners from "./Partners/Partners";
import QA from "./Q&A/Q&A";
import HowItWork from "./HowItWork/HowItWork";
import DownloadSection from "./DownloadSection/DownloadSection";
import ContactSection from "./ContactSection/ContactSection";

function Home() {
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!data) {
      axios({
        method: "get",
        url: "https://cut-backend-2.appssquare.com/api/homePage",
        headers: {
          "X-Language":
            localStorage.getItem("lng") && localStorage.getItem("lng"),
        },
      }).then(function (response) {
        setData(response.data);
      });
    }
  });
  return (
    <div>
      <Header dataRes={data} />
      <div className="home">
        <About dataRes={data} />
        <Services dataRes={data} />
        <Features dataRes={data} />
        <Partners dataRes={data} />
        <QA dataRes={data} />
        <HowItWork dataRes={data} />
        <DownloadSection dataRes={data} />
        <ContactSection dataRes={data} />
      </div>
    </div>
  );
}

export default Home;
