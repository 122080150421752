import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

//style
import "./downloadSection.scss";
import useTranslation from "../../customHooks/translation";

function DownloadSection() {
  return (
    <>
      <section className="downloadSection" name="DownloadSection">
        <Container fluid>
          <Row className="justify-content-end align-item-center">
            <Col
              className="d-flex text-center flex-column align-item-center justify-content-center p-0"
              md={6}
            >
              <h3 className="mb-4">
                {useTranslation().downloadSection.downloadMessage}
              </h3>
              <Row className="row-btns">
                <Col md={6}>
                  <Button
                    className="downloadSection__btn text-left"
                    variant="light"
                  >
                    <FontAwesomeIcon icon={faApple} />
                    Download on the
                    <p className="m-0">App Store</p>
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    className="downloadSection__btn text-left"
                    variant="light"
                  >
                    <FontAwesomeIcon icon={faGooglePlay} />
                    Get it on
                    <p className="m-0">Play Store</p>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default DownloadSection;
