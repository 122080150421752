import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

//style
import './verificationCode.scss';

//components
import NavBarRegister from '../NavBarRegister/NavBarRegister';

function SuccessModal() {
  const [show, setShow] = useState(true);
  const [code, setCode] = useState(true);
  const [display, setDisplay] = useState(false);

  const handleClose = () => setDisplay(true);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal size='lg' className='text-center' show={show} onHide={handleClose}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon={faEnvelopeOpen} />
          {!display ? (
            <>
              <h5>Account is pending</h5>
              <p>Please verify your phone by the code you received </p>
              <Container fluid>
                <Row className='mb-3 text-center'>
                  <Col md='12' lg='12'>
                    <Form.Group controlId='Phone'>
                      <Form.Control
                        type='number'
                        placeholder='Code'
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <h5>Verification success</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          {display ? (
            <Link to='/' className='Send-btn'>
              Home
            </Link>
          ) : (
            <Button type='submit' className='Send-btn' onClick={handleClose}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SuccessModal;
